export enum BasketActionTypes {
    ADD_ITEM = 'BASKET_ADD_ITEM',
    ADD_ITEMS = 'BASKET_ADD_ITEMS',
    UPDATE_QUANTITY = 'BASKET_UPDATE_QUANTITY',
    REMOVE_ITEM = 'BASKET_REMOVE_ITEM',
    SET_BASKET = 'BASKET_SET_BASKET',
    SET_BASKET_LOCATION_ID = 'BASKET_SET_BASKET_LOCATION_ID',
    RESET_BASKET = 'BASKET_RESET_BASKET',
    ADD_AWARD_ADJUSTMENT = 'ADD_AWARD_ADJUSTMENT',
    REMOVE_REWARD = 'REMOVE_REWARD',
    RESET_REWARDS = 'RESET_REWARDS',
    SET_DELIVERY_LOCATION = 'BASKET_SET_DELIVERY_LOCATION',
    SET_TIMESLOT = 'BASKET_SET_TIMESLOT',
    SET_CHECK_ID = 'BASKET_SET_CHECK_NUMBER',
    SET_NOTE = 'SET_NOTE',
    SET_PREP_TIME_PADDING = 'BASKET_SET_PREP_TIME_PADDING',
    BASKET_SET_SUGGESTIONS_SHOWED = 'BASKET_SET_SUGGESTIONS_SHOWED',
    UPDATE_QUANTITY_BY_ID = 'UPDATE_QUANTITY_BY_ID'
}
