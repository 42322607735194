import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { DigitalWallets } from 'src/integrations/PaymentProvider/DigitalWallets';
import { OrderPaymentType } from 'components/order/model/Order';
import { UserCard } from 'components/user/model/User';
import { getFadeDownGradient } from 'lib/helpers';
import { isNumber } from 'lib/typeInference';
import { useCurrencyString } from 'lib/useCurrencyString';
import { PAY_MAX_WIDTH } from 'pages/pay';
import { ButtonV2 } from '../ui/PayButton';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'fixed',
            bottom: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            width: '100%',
            maxWidth: PAY_MAX_WIDTH,
            right: 0,
            padding: theme.spacing(1),
            background: getFadeDownGradient(
                theme.palette.background.default,
                [0, 0.5, 1],
                ['0%', '20%', ' 100%']
            )
        },
        placeholder: {
            width: '100%'
        }
    })
);

interface PaymentButtonsProps {
    amount?: number;
    loading?: boolean;
    cards?: UserCard[];
    isSplit?: boolean;
    selectedWallet: OrderPaymentType;
    outOfTransactionLimit?: boolean;
    multiPartOrderingEnabled?: boolean;
    onClick: (forced?: boolean) => void;
    onDigitalPaymentMade: (cardNonce: string, walletType: OrderPaymentType) => void;
    onBillSplit: () => void;
    onAddMoreItems: () => void;
}

export const PaymentButtons: React.FC<PaymentButtonsProps> = ({
    cards,
    amount,
    isSplit,
    loading,
    selectedWallet,
    outOfTransactionLimit,
    onClick,
    onBillSplit,
    onAddMoreItems,
    onDigitalPaymentMade,
    multiPartOrderingEnabled = false
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const formatCurrency = useCurrencyString();
    const [height, setHeight] = React.useState(0);
    const ref = React.useRef<HTMLDivElement>(null);
    const handleClick = React.useCallback(() => {
        onClick(amount === 0);
    }, [amount, onClick]);
    React.useEffect(() => {
        const rect = ref.current?.getBoundingClientRect();
        if (rect && rect.height !== height) {
            setHeight(rect.height);
        }
        const interval = setInterval(() => {
            const rect = ref.current?.getBoundingClientRect();
            if (rect && rect.height !== height) {
                setHeight(rect.height);
            }
        }, 3000);
        return () => clearInterval(interval);
    }, [amount, loading, height]);
    const isDigitalPayment = React.useMemo(
        () => selectedWallet === OrderPaymentType.APPLEPAY || selectedWallet === OrderPaymentType.GOOGLEPAY,
        [selectedWallet]
    );
    const dataCy = React.useMemo(() => {
        if ((amount ?? 0) > 0 && selectedWallet === OrderPaymentType.CARD_ON_FILE && !cards?.length) {
            return 'add-card';
        }
        return 'submit';
    }, [amount, cards?.length, selectedWallet]);
    const content = React.useMemo(() => {
        if ((amount ?? 0) > 0 && selectedWallet === OrderPaymentType.CARD_ON_FILE && !cards?.length) {
            return t('CHECKOUT_ADD_CARD');
        }
        return t(isSplit ? 'BILL_SPLIT_BY_PERCENT_PAY_BTN' : 'PAT_QUICKPAY_PAY_BUTTON', {
            amount: formatCurrency(amount ?? 0)
        });
    }, [amount, cards?.length, formatCurrency, isSplit, selectedWallet, t]);
    if (!isNumber(amount) || loading) {
        return null;
    }
    if (outOfTransactionLimit) {
        return (
            <React.Fragment>
                <div className={classes.root} ref={ref}>
                    {multiPartOrderingEnabled && (
                        <Box paddingBottom={0.25}>
                            <ButtonV2 onClick={onAddMoreItems} secondary data-cy="split">
                                {t('BILL_SUMMARY_ADD_ITEMS_BUTTON')}
                            </ButtonV2>
                        </Box>
                    )}
                    <Box paddingBottom={0.25}>
                        <ButtonV2 onClick={onBillSplit} secondary data-cy="split">
                            {isSplit ? t('PAT_QUICKPAY_CHANGE_SPLIT') : t('VIEW_BILL_SPLIT_BTN')}
                        </ButtonV2>
                    </Box>
                    <ButtonV2 onClick={handleClick} data-cy={dataCy}>
                        {content}
                    </ButtonV2>
                </div>
                <div className={classes.placeholder} style={{ height }} />
            </React.Fragment>
        );
    }
    return (
        <React.Fragment>
            <div className={classes.root} ref={ref}>
                {multiPartOrderingEnabled && (
                    <Box paddingBottom={0.25}>
                        <ButtonV2 onClick={onAddMoreItems} secondary data-cy="add-more">
                            {t('BILL_SUMMARY_ADD_ITEMS_BUTTON')}
                        </ButtonV2>
                    </Box>
                )}
                <Box paddingBottom={0.25}>
                    <ButtonV2 onClick={onBillSplit} secondary data-cy="split">
                        {isSplit ? t('PAT_QUICKPAY_CHANGE_SPLIT') : t('VIEW_BILL_SPLIT_BTN')}
                    </ButtonV2>
                </Box>
                {(amount === 0 || !isDigitalPayment) && (
                    <ButtonV2 onClick={handleClick} data-cy={dataCy}>
                        {content}
                    </ButtonV2>
                )}
                {amount !== 0 && isDigitalPayment && (
                    <DigitalWallets
                        legacyUi={false}
                        total={amount}
                        selectedWallet={selectedWallet}
                        onDigitalPaymentMade={onDigitalPaymentMade}
                    >
                        {content}
                    </DigitalWallets>
                )}
            </div>
            <div className={classes.placeholder} style={{ height }} />
        </React.Fragment>
    );
};
