import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, createStyles, Grid, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import { useInputState } from 'lib/useInputState';
import { ButtonV2 } from '../ui/PayButton';

interface PayOrderNoteDialogProps {
    title: string;
    description: string;
    initialValue?: string;
    onChange: (title: string, value: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            width: '100%',
            overflow: 'hidden'
        },
        title: {
            textAlign: 'center',
            width: '100%',
            fontFamily: `TenantFont, ${theme.typography.fontFamily}`,
            fontWeight: 'bold',
            color: theme.palette.titleTextColour
        }
    })
);

export const PayOrderNoteDialog: React.FC<PayOrderNoteDialogProps> = ({
    title,
    onChange,
    initialValue,
    description
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [value, handleChange] = useInputState(initialValue ?? '');
    const handleSave = React.useCallback(() => {
        onChange(title, value);
    }, [value, onChange, title]);
    const handleKeyPress = React.useCallback(
        (e: React.KeyboardEvent) => {
            if (e.key === 'Enter') {
                handleSave();
            }
        },
        [handleSave]
    );
    return (
        <Box className={classes.wrapper}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h6" className={classes.title} align="center">
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        autoFocus
                        value={value}
                        onChange={handleChange}
                        onKeyDown={handleKeyPress}
                        label={description}
                        variant="outlined"
                        fullWidth
                        color="secondary"
                    />
                </Grid>
                <Grid item xs={12}>
                    <ButtonV2 variant="contained" color="primary" onClick={handleSave} fullWidth>
                        {t('BASKET_ORDER_NOTES_SAVE')}
                    </ButtonV2>
                </Grid>
            </Grid>
        </Box>
    );
};
