import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useQuery } from 'src/hooks/useQuery';
import { isEmptyString } from 'lib/typeInference';
import { useInputState } from 'lib/useInputState';
import { BaseRouteParams } from 'pages/routes';
import { ApplicationState } from 'store/store';
import { ButtonV2 } from '../ui/PayButton';
import { PayBaseModal } from './PayBaseModal';
import { Image } from 'lib/Image';

interface PayTableSelector {
    open: boolean;
    disableBackdropClick?: boolean;
    onChange: (tableNumber?: string, checkId?: string) => void;
    onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    button: {
        textTransform: 'none',
        marginTop: theme.spacing(1)
    },
    typography: {
        fontSize: '15px',
        fontWeight: 700
    },
    textField: {
        width: 86,
        margin: theme.spacing(1, 0)
    },
    checkInput: {
        width: 108
    },
    input: {
        padding: theme.spacing(1.5, 2),
        textAlign: 'center'
    }
}));

export const PayTableSelector: React.FC<PayTableSelector> = ({
    open,
    disableBackdropClick,
    onClose,
    onChange
}) => {
    const classes = useStyles();
    const initialTableNumber = useQuery('tableNumber');
    const initialCheckId = useQuery('checkId');
    const { merchantId } = useParams<BaseRouteParams>();
    const { t } = useTranslation();
    const { settings } = useSelector((state: ApplicationState) => state.settings);

    const [tableNumber, onChangeTableNumber] = useInputState(initialTableNumber);
    const [checkId, onChangeCheckId] = useInputState(initialCheckId);
    const [showLogo, setShowLogo] = React.useState<boolean>(true);
    const showTableNumber = React.useMemo(
        () =>
            !settings?.payAtTable?.retrievalBy ||
            settings?.payAtTable?.retrievalBy === 'BOTH' ||
            settings?.payAtTable?.retrievalBy === 'TABLE_NUMBER',
        [settings?.payAtTable?.retrievalBy]
    );
    const showCheckId = React.useMemo(
        () =>
            settings?.payAtTable?.retrievalBy === 'BOTH' ||
            settings?.payAtTable?.retrievalBy === 'CHECK_NUMBER',
        [settings?.payAtTable?.retrievalBy]
    );
    const disableButton = React.useMemo(
        () => isEmptyString(checkId) && isEmptyString(tableNumber),
        [checkId, tableNumber]
    );
    const handleClose = React.useCallback(() => {
        if (!disableBackdropClick) {
            onClose();
        }
    }, [disableBackdropClick, onClose]);
    const handleSubmit = React.useCallback(() => {
        if (!disableButton) {
            onChange(tableNumber, checkId);
        }
    }, [checkId, disableButton, onChange, tableNumber]);
    const handleEnterPress = React.useCallback(
        (e: React.KeyboardEvent) => {
            if (e.key === 'Enter') {
                return handleSubmit();
            }
            // Prevent user entering anything that is not number or backspace
            const regex = new RegExp(/[0-9]/g);
            if (!regex.test(e.key) && e.keyCode !== 8) {
                e.preventDefault();
            }
        },
        [handleSubmit]
    );
    const handleLogoFallbackError = React.useCallback(() => {
        setShowLogo(false);
    }, []);
    return (
        <PayBaseModal open={!!open} onClose={handleClose}>
            <Box px={1} py={1.5} display="flex" flexDirection="column" alignItems="center">
                {!!showLogo && (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        marginBottom={2}
                        marginTop={2}
                    >
                        <Image
                            src={`${process.env.MEDIA_URL}/tenants/${merchantId}/app_assets/pat_logo`}
                            onFallbackError={handleLogoFallbackError}
                            height="60px"
                        />
                    </Box>
                )}
                {showTableNumber && (
                    <React.Fragment>
                        <Typography className={classes.typography} align="center">
                            {t('PAT_QUICKPAY_ENTER_TABLE')}
                        </Typography>
                        <TextField
                            variant="outlined"
                            name="tableNumber"
                            value={tableNumber ?? ''}
                            type="number"
                            onChange={onChangeTableNumber}
                            onKeyPress={handleEnterPress}
                            className={classes.textField}
                            inputProps={{ className: classes.input }}
                            InputProps={{
                                autoComplete: 'off',
                                autoFocus: true,
                                inputMode: 'numeric'
                            }}
                        />
                    </React.Fragment>
                )}
                {showCheckId && (
                    <React.Fragment>
                        <Typography className={classes.typography} align="center">
                            {t('PAT_QUICKPAY_ENTER_CHECK')}
                        </Typography>
                        <TextField
                            variant="outlined"
                            name="checkId"
                            value={checkId ?? ''}
                            type="number"
                            onChange={onChangeCheckId}
                            onKeyPress={handleEnterPress}
                            className={clsx(classes.textField, classes.checkInput)}
                            inputProps={{ className: classes.input }}
                            InputProps={{
                                autoComplete: 'off',
                                autoFocus: true,
                                inputMode: 'numeric'
                            }}
                        />
                    </React.Fragment>
                )}
                <Box width="100%">
                    <ButtonV2 disabled={disableButton} onClick={handleSubmit}>
                        {t('PAT_QUICKPAY_TABLE_SELECTION_SUBMIT')}
                    </ButtonV2>
                </Box>
            </Box>
        </PayBaseModal>
    );
};
