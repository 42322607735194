import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { useRouteScenario } from 'src/hooks/useRouteScenario';
import { getOrderNotes, OrderNote } from 'components/location/model/Location';
import { OrderScenario } from 'components/order/model/Order';
import { setBasketNote } from 'store/basket/basketActions';
import { ApplicationState } from 'store/store';
import { PayorderNoteItem } from './PayOrderNoteItem';
import { PayOrderNoteDialog } from './PayOrderNoteDialog';
import { PayBaseModal } from '../modals/PayBaseModal';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            marginBottom: theme.spacing(0),
            fontWeight: 700
        }
    })
);

interface PayOrderNotesProps {
    loading?: boolean;
}

export const PayOrderNotes: React.FC<PayOrderNotesProps> = ({ loading }) => {
    const classes = useStyles();
    const { notes: basketNotes } = useSelector((state: ApplicationState) => state.basket);
    const [scenario] = useRouteScenario();
    const { currentLocation } = useSelector((state: ApplicationState) => state.locations);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const notes = React.useMemo(() => getOrderNotes(currentLocation, scenario), [currentLocation, scenario]);
    const showNotes = React.useMemo(
        () =>
            !loading &&
            Array.isArray(notes) &&
            notes.length &&
            (scenario === OrderScenario.PREORDER ||
                scenario === OrderScenario.ORDER_TO_TABLE ||
                scenario === OrderScenario.TABLE),
        [loading, notes, scenario]
    );
    const [openIndex, setOpenIndex] = React.useState(-1);
    const handleCancel = React.useCallback(() => {
        setOpenIndex(-1);
    }, []);
    const handleItemClick = React.useCallback((value: number) => {
        setOpenIndex(value);
    }, []);
    const selectedNote = React.useMemo(() => {
        if (openIndex < 0) {
            return null;
        }
        const note = notes[openIndex];
        if (!note) {
            return null;
        }
        return { ...note, value: basketNotes[note.title] };
    }, [basketNotes, notes, openIndex]);
    const handleChange = React.useCallback(
        (title: string, value: string) => {
            setBasketNote(title, value)(dispatch);
            setOpenIndex(-1);
        },
        [dispatch]
    );
    const renderItem = React.useCallback(
        (note: OrderNote, index: number) => (
            <PayorderNoteItem
                key={`order-note-${note.title}`}
                title={note.title}
                index={index}
                onClick={handleItemClick}
                value={basketNotes[note.title]}
            />
        ),
        [basketNotes, handleItemClick]
    );
    if (!showNotes) {
        return null;
    }
    return (
        <Box p={2}>
            <Typography className={classes.title}>{t('BILL_CHECKOUT_ORDER_NOTES')}</Typography>
            <Box>{notes.map(renderItem)}</Box>
            <PayBaseModal open={!!selectedNote} onClose={handleCancel}>
                {!!selectedNote && (
                    <PayOrderNoteDialog
                        title={selectedNote.title}
                        description={selectedNote.description}
                        onChange={handleChange}
                        initialValue={selectedNote.value}
                    />
                )}
            </PayBaseModal>
        </Box>
    );
};
