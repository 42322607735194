import React from 'react';
import { Box, makeStyles, Theme, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { LoadingTypography } from 'lib/LoadingTypography';
import { CheckCircle } from '../ui/CheckCircle';
import { ErrorCircle } from '../ui/ErrorCircle';
import { ButtonV2 } from '../ui/PayButton';

interface PayGiftCardModalStrings {
    title?: string;
    subtitle?: string;
    hint?: string;
    primary?: string;
    secondary?: string;
}

interface PayGiftCardApplyProps {
    giftCardNumber?: string;
    loading?: boolean;
    error?: string;
    onPrimaryClick?: () => void;
    onSecondaryClick?: () => void;
    localisation?: PayGiftCardModalStrings;
}

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        fontSize: '24px',
        fontWeight: 700
    },
    card: {
        fontSize: '16px',
        fontWeight: 400
    },
    subtitle: {
        fontSize: '18px',
        fontWeight: 700,
        margin: theme.spacing(2, 0)
    },
    text: {
        fontSize: '20px',
        fontWeight: 500
    },
    img: {
        margin: theme.spacing(2, 0, 3, 0)
    }
}));

export const PayGiftCardApply: React.FC<PayGiftCardApplyProps> = ({
    giftCardNumber,
    loading,
    error,
    onPrimaryClick,
    localisation = {},
    onSecondaryClick
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const image = React.useMemo(() => {
        if (loading) {
            return <Skeleton className={classes.img} variant="circle" height={83} width={83} />;
        }
        if (error) {
            return <ErrorCircle className={classes.img} color={theme.palette.primary.main} />;
        }
        return <CheckCircle className={classes.img} color={theme.palette.primary.main} />;
    }, [classes.img, error, loading, theme.palette.primary.main]);
    return (
        <Box px={1} py={1.5}>
            <Box display="flex" flexDirection="column" alignItems="center">
                <LoadingTypography loading={loading} width={120} className={classes.title}>
                    {localisation.title}
                </LoadingTypography>
                <LoadingTypography className={classes.card} width={100} loading={loading} align="center">
                    {giftCardNumber}
                </LoadingTypography>
                <LoadingTypography className={classes.subtitle} loading={loading} width={160} align="center">
                    {localisation.subtitle}
                </LoadingTypography>
                {image}
                <LoadingTypography className={classes.text} loading={loading} width={160}>
                    {localisation.hint}
                </LoadingTypography>
                <ButtonV2 onClick={onPrimaryClick} disabled={loading}>
                    {localisation.primary ?? ' '}
                </ButtonV2>
                <ButtonV2 onClick={onSecondaryClick} disabled={loading} variant="outlined">
                    {localisation.secondary ?? ' '}
                </ButtonV2>
            </Box>
        </Box>
    );
};
