import React from 'react';
import { Box, ListItem, makeStyles, Theme, Typography } from '@material-ui/core';
import { IItemReadResourceV10 } from 'components/basket/model/Basket';
import {
    getPayModifierProductDetails,
    getPayProductOptionsDetails,
    ProductModifierPayItem
} from 'components/menu/model/Menu';
import { addOpacity } from 'lib/helpers';
import { useCurrencyString } from 'lib/useCurrencyString';

interface PayOrderItemProps {
    item: IItemReadResourceV10;
    allItems: IItemReadResourceV10[];
    onClick?: (id: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        flexDirection: 'column',
        padding: theme.spacing(0.5, 0),
        alignItems: 'flex-start',
        '& .MuiTypography-root': {
            fontSize: '14px'
        }
    },
    productWrapper: {
        display: 'flex',
        width: '100%',
        flexShrink: 0
    },
    productQuantity: {
        minWidth: '24px'
    },
    productInfo: {
        flex: 1
    },
    modifier: {
        color: addOpacity(theme.palette.text.primary, 0.4),
        paddingLeft: theme.spacing(3)
    },
    quantity: {
        color: addOpacity(theme.palette.text.primary, 0.4),
        paddingRight: 0
    },
    productPrice: {
        minWidth: '64px',
        textAlign: 'right'
    },
    modifierQuantity: {
        display: 'inline-block'
    }
}));

export const PayOrderItem: React.FC<PayOrderItemProps> = ({ item, allItems, onClick }) => {
    const classes = useStyles();
    const getCurrencyString = useCurrencyString();

    const [modifierProducts, calculatedItemPrice] = React.useMemo(() => {
        const products = allItems.filter(
            currentItem => currentItem.parentReferenceId && currentItem.parentReferenceId === item.referenceId
        );
        const [modifierString, modifierPrice] = getPayModifierProductDetails(products, item.quantity);
        return [modifierString, item.cost + modifierPrice];
    }, [allItems, item.quantity, item.cost, item.referenceId]);
    const renderProductModifier = React.useCallback(
        (productModifier: ProductModifierPayItem, index: number) => (
            <Typography className={classes.modifier} key={`product-modifier-${index}`}>
                <span className={classes.modifierQuantity}>
                    {productModifier.quantity > 1 ? `${productModifier.quantity}x` : ''}
                </span>
                {productModifier.title}
                {productModifier.price ? ` (${getCurrencyString(productModifier.price)})` : ''}
            </Typography>
        ),
        [classes.modifierQuantity, classes.modifier, getCurrencyString]
    );
    const [modifierOptions, calculatedOptionsPrice] = React.useMemo(
        () => getPayProductOptionsDetails(item.modifiers),
        [item.modifiers]
    );

    const totalPrice = React.useMemo(
        () => Math.round((calculatedItemPrice + calculatedOptionsPrice) * item.quantity * 100) / 100,
        [calculatedItemPrice, calculatedOptionsPrice, item.quantity]
    );

    const handleClick = React.useCallback(() => {
        if (onClick) {
            onClick(item.id);
        }
    }, [item.id, onClick]);

    if (item.parentReferenceId) {
        return null;
    }
    return (
        <ListItem className={classes.root} disableGutters onClick={handleClick}>
            <Box className={classes.productWrapper}>
                <Typography className={classes.productQuantity}>
                    {item.quantity}
                    {'x'}
                </Typography>
                <Box className={classes.productInfo}>
                    <Typography>{item.productName}</Typography>
                </Box>
                {item.quantity > 1 && (
                    <Typography className={classes.quantity}>
                        {getCurrencyString(calculatedItemPrice)}
                    </Typography>
                )}
                <Typography className={classes.productPrice}>{getCurrencyString(totalPrice)}</Typography>
            </Box>
            {modifierProducts.map(renderProductModifier)}
            {modifierOptions.map(renderProductModifier)}
        </ListItem>
    );
};
