import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Button,
    createStyles,
    FormControl,
    FormControlLabel,
    FormLabel,
    makeStyles,
    MenuItem,
    Radio,
    RadioGroup,
    Theme,
    Typography
} from '@material-ui/core';
import clsx from 'clsx';
import { Form, Formik, FormikHelpers } from 'formik';
import noop from 'lodash/noop';
import { useResponsive } from 'src/hooks/useResponsive';
import { useRouteScenario } from 'src/hooks/useRouteScenario';
import { SuggestionsProvider } from 'src/hooks/useSuggestionsContext';
import * as Yup from 'yup';
import { MenuSearchButton } from 'components/menu/MenuSearchButton';
import { MenuSearch } from 'components/menu/MenuSearchDialog';
import { OrderScenario } from 'components/order/model/Order';
import { TextFormField } from 'lib/form/TextFormField';
import { DineInIcon } from 'lib/icons/DineInIcon';
import { PayBillIcon } from 'lib/icons/PayBillIcon';
import { MerchantBrandedDialog } from 'lib/MerchantBrandedDialog';
import { isDefined, isEmptyString, isNotNull } from 'lib/typeInference';
import { setCheckNumber, setDeliveryLocation } from 'store/basket/basketActions';
import { ApplicationState } from 'store/store';
import { LoadingButton } from 'ui/LoadingButton';
import { locationApi } from './locationApi';
import { useQuery } from 'src/hooks/useQuery';

interface IProps {
    pickerTitle?: 'location' | 'scenario';
    locationId?: string;
    isDialogOpenInitially?: boolean;
    onTableChange?: ({ previous, current }: { previous?: string; current: string }) => void;
    checkSelectionEnabled?: boolean;
    disableBottomSpace?: boolean;
    hidden?: boolean;
    renderPickerView?: (onEdit: () => void) => JSX.Element | null;
    searchButton?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontFamily: `TenantFont, ${theme.typography.fontFamily}`
        },
        confirmButton: {
            marginTop: theme.spacing(1),
            padding: theme.spacing(1)
        },
        editText: {
            marginLeft: theme.spacing(1.5),
            color: theme.palette.primary.contrastText
        },
        noPadding: {
            padding: theme.spacing(0)
        },
        rootBox: ({
            disableBottomSpace,
            isDesktop
        }: {
            disableBottomSpace: boolean;
            isDesktop: boolean;
        }) => ({
            backgroundColor: theme.palette.titleTextColour,
            width: '100%',
            marginBottom: theme.spacing(disableBottomSpace ? 0 : 1),
            borderRadius: isDesktop ? theme.shape.borderRadius : 0
        }),
        paper: {
            backgroundColor: 'transparent'
        },
        textBold: {
            fontWeight: 700
        },
        textColor: {
            color: theme.palette.background.default
        },
        leaveWhiteSpace: {
            whiteSpace: 'pre-wrap'
        },
        icon: {
            stroke: theme.palette.background.default,
            fill: theme.palette.background.default,
            fontSize: 'inherit'
        },
        tableButton: {
            marginLeft: theme.spacing(1.5)
        }
    })
);

function getTitleString(scenario: OrderScenario) {
    switch (scenario) {
        case OrderScenario.ORDER_TO_TABLE:
            return 'OTT_TITLE';
        case OrderScenario.TAB:
        case OrderScenario.TABLE:
            return 'TAB_TITLE';
        default:
            return '';
    }
}

export const OrderToTableLocationPicker: React.FC<IProps> = ({
    pickerTitle = 'scenario',
    locationId,
    onTableChange,
    isDialogOpenInitially = false,
    checkSelectionEnabled = false,
    disableBottomSpace = false,
    renderPickerView,
    hidden,
    searchButton
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { currentLocation } = useSelector((state: ApplicationState) => state.locations);
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const { deliveryLocation, checkId } = useSelector((state: ApplicationState) => state.basket);
    const { isDesktop } = useResponsive();
    const classes = useStyles({
        disableBottomSpace: !!disableBottomSpace,
        isDesktop
    });
    const [dialogOpen, setDialogOpen] = React.useState(isDialogOpenInitially);
    const [fetchBy, setFetchBy] = React.useState<string>('CHECK_NUMBER');
    const [scenarioFromRoute] = useRouteScenario();

    const currentSearch = useQuery('search');
    const tableNumberQuery = useQuery('tableNumber');
    const [openMenuSearch, setOpenMenuSearch] = React.useState(() => {
        if (isNotNull(currentSearch) && isDefined(currentSearch) && !isEmptyString(currentSearch)) {
            return true;
        }
        return false;
    });

    const scenarioToUse = React.useMemo(
        () => scenarioFromRoute ?? OrderScenario.PREORDER,
        [scenarioFromRoute]
    );

    const tableNumber = React.useMemo(
        () => tableNumberQuery || deliveryLocation,
        [deliveryLocation, tableNumberQuery]
    );

    const canChangeTableNumber = React.useMemo(() => {
        if (!!tableNumber) {
            if (
                settings?.payAtTable?.canChangeTableNumber !== undefined ||
                settings?.orderToTable?.canChangeTableNumber !== undefined
            ) {
                return (
                    settings?.payAtTable?.canChangeTableNumber || settings?.orderToTable?.canChangeTableNumber
                );
            }
        }

        return true;
    }, [
        tableNumber,
        settings?.orderToTable?.canChangeTableNumber,
        settings?.payAtTable?.canChangeTableNumber
    ]);
    const isPickerAvailable = React.useMemo(() => {
        if (scenarioToUse === OrderScenario.TABLE) {
            return false;
        }
        if (settings?.payAtTable?.retrievalBy === 'TABLE_NUMBER') {
            return canChangeTableNumber;
        }
        if (settings?.payAtTable?.retrievalBy === 'BOTH' && !checkSelectionEnabled && !canChangeTableNumber) {
            return false;
        }
        return true;
    }, [canChangeTableNumber, checkSelectionEnabled, scenarioToUse, settings?.payAtTable?.retrievalBy]);

    const retrievalBy = React.useMemo(() => {
        if (!canChangeTableNumber && settings?.payAtTable?.retrievalBy === 'BOTH') {
            return 'CHECK_NUMBER';
        }

        return settings?.payAtTable?.retrievalBy;
    }, [canChangeTableNumber, settings?.payAtTable?.retrievalBy]);
    const initialValues = React.useMemo(() => {
        if (retrievalBy) {
            if (
                (retrievalBy === 'CHECK_NUMBER' || (retrievalBy === 'BOTH' && fetchBy === 'CHECK_NUMBER')) &&
                checkId
            ) {
                return { checkId: checkId || '' };
            }
            return { tableNumber: tableNumber ?? '' };
        }

        return { tableNumber: tableNumber || '' };
    }, [checkId, fetchBy, retrievalBy, tableNumber]);
    const [title, setTitle] = React.useState<string>('');

    React.useEffect(() => {
        if (!!locationId && pickerTitle === 'location') {
            locationApi.getLocation(locationId).then(location => {
                setTitle(location.title);
            });
        } else {
            setTitle(t(getTitleString(scenarioToUse)));
        }
    }, [locationId, pickerTitle, scenarioToUse, t]);

    React.useEffect(() => {
        if (!isDialogOpenInitially) {
            setDialogOpen(false);
        }
    }, [isDialogOpenInitially]);

    const handleEdit = React.useCallback(() => {
        setDialogOpen(true);
    }, []);

    const handleDialogClose = React.useCallback(() => {
        setDialogOpen(false);
    }, []);

    const handleTableUpdated = React.useCallback(
        (values: Record<string, string | undefined>) => {
            if (onTableChange) {
                onTableChange({ previous: tableNumber, current: values.tableNumber || '' });
            }
            if (scenarioToUse === OrderScenario.TABLE && retrievalBy) {
                if (
                    retrievalBy === 'CHECK_NUMBER' ||
                    (retrievalBy === 'BOTH' && fetchBy === 'CHECK_NUMBER')
                ) {
                    setCheckNumber(values.checkId)(dispatch);
                } else {
                    setDeliveryLocation(values.tableNumber)(dispatch);
                }
            } else {
                setDeliveryLocation(values.tableNumber)(dispatch);
            }
            setDialogOpen(false);
        },
        [tableNumber, dispatch, fetchBy, onTableChange, retrievalBy, scenarioToUse]
    );

    const handleTableNumberKeyDown = React.useCallback(
        (setFieldTouched: FormikHelpers<any>['setFieldTouched'], field: string) =>
            (event: React.KeyboardEvent) => {
                const regex = new RegExp(/[0-9]/g);
                // Prevent user entering anything that is not number or backspace
                if (!regex.test(event.key) && event.keyCode !== 8) {
                    event.preventDefault();
                }

                setFieldTouched(field, true);
            },
        []
    );

    const handleTableNumberChange = React.useCallback(
        (setFieldValue: FormikHelpers<any>['setFieldValue'], field: string) =>
            (event: React.FocusEvent<HTMLInputElement>) => {
                setFieldValue(field, event.target.value.replace(/[^0-9]/g, ''), true);
            },
        []
    );

    const handleCloseMenuSearch = React.useCallback(() => {
        setOpenMenuSearch(false);
    }, []);
    const handleOpenMenuSearch = React.useCallback(() => {
        setOpenMenuSearch(true);
    }, []);

    const deliveryLocationValidation = React.useMemo(() => {
        const availableZones =
            currentLocation && currentLocation.zones && Object.values(currentLocation.zones).flat();
        const zonesValidation: [string, string, (value: any) => boolean] = [
            'zones-validation',
            t('ERROR_TABLE_NUMBER_INVALID'),
            value => {
                if (!!availableZones) {
                    return availableZones.some(([from, to]) => value >= from && value <= to);
                }

                return true;
            }
        ];

        if (settings && scenarioToUse === OrderScenario.TABLE && !!retrievalBy) {
            if (retrievalBy === 'TABLE_NUMBER' || (retrievalBy === 'BOTH' && fetchBy === 'TABLE_NUMBER')) {
                return Yup.object().shape({
                    tableNumber: Yup.string()
                        .required(t('FORMS_VALIDATION_REQUIRED'))
                        .test(...zonesValidation)
                });
            }
            if (retrievalBy === 'CHECK_NUMBER' || (retrievalBy === 'BOTH' && fetchBy === 'CHECK_NUMBER')) {
                return Yup.object().shape({
                    checkId: Yup.string().required(t('FORMS_VALIDATION_REQUIRED'))
                });
            }
        }

        return Yup.object().shape({
            tableNumber: Yup.string()
                .required(t('FORMS_VALIDATION_REQUIRED'))
                .test(...zonesValidation)
        });
    }, [currentLocation, settings, scenarioToUse, retrievalBy, t, fetchBy]);
    const caption = React.useMemo(() => {
        if (
            scenarioToUse === OrderScenario.ORDER_TO_TABLE ||
            !checkSelectionEnabled ||
            (scenarioToUse === OrderScenario.TABLE && !retrievalBy)
        ) {
            return t(tableNumber ? 'LABEL_TABLE_NUMBER_PLACEHOLDER' : 'OTT_NO_TABLE_NUMBER', {
                tableNumber: tableNumber || 'None'
            });
        }
        if (settings) {
            if (
                retrievalBy === 'TABLE_NUMBER' ||
                (retrievalBy === 'BOTH' && fetchBy === 'TABLE_NUMBER') ||
                !!tableNumber
            ) {
                return t(tableNumber ? 'LABEL_TABLE_NUMBER_PLACEHOLDER' : 'OTT_NO_TABLE_NUMBER', {
                    tableNumber: tableNumber || 'None'
                });
            }
            return t(checkId ? 'PAT_CHECK_NO' : 'PAT_EMPTY_CHECK_NO', {
                checkId: checkId || 'None'
            });
        }
    }, [checkId, checkSelectionEnabled, tableNumber, fetchBy, settings, retrievalBy, scenarioToUse, t]);

    const canBeClosed = React.useMemo(() => {
        if (scenarioToUse === OrderScenario.TABLE) {
            if (settings) {
                if (!retrievalBy || !checkSelectionEnabled) {
                    return !tableNumber;
                }
                switch (retrievalBy) {
                    case 'CHECK_NUMBER':
                        return !checkId;
                    case 'TABLE_NUMBER':
                        return !tableNumber;
                    default:
                        return !(tableNumber || checkId);
                }
            }
        }

        return !tableNumber;
    }, [checkId, checkSelectionEnabled, tableNumber, settings, retrievalBy, scenarioToUse]);
    const handleFetchByChange = React.useCallback(
        (_: React.ChangeEvent<HTMLInputElement>, fetchType: string) => {
            setFetchBy(fetchType);
        },
        []
    );
    const dialogTitle = React.useMemo(() => {
        switch (scenarioToUse) {
            case OrderScenario.ORDER_TO_TABLE:
                return t('OTT_TITLE');
            case OrderScenario.TABLE:
                return t('PAT_START_TITLE');
            default:
                return t('OTT_NUMBER');
        }
    }, [scenarioToUse, t]);
    const subtitle = React.useMemo(() => {
        switch (scenarioToUse) {
            case OrderScenario.ORDER_TO_TABLE:
                return t('OTT_START_MANUAL_TITLE');
            case OrderScenario.TABLE:
                if (retrievalBy === 'TABLE_NUMBER' || retrievalBy === 'CHECK_NUMBER') {
                    return t('OTT_START_MANUAL_TITLE');
                }
                return '';

            default:
                return '';
        }
    }, [retrievalBy, scenarioToUse, t]);
    const icon = React.useMemo(() => {
        switch (scenarioToUse) {
            case OrderScenario.ORDER_TO_TABLE:
                return <DineInIcon className={classes.icon} />;
            case OrderScenario.TABLE:
                return <PayBillIcon className={classes.icon} />;
            default:
                return null;
        }
    }, [classes.icon, scenarioToUse]);

    return (
        <>
            {!hidden &&
                (!renderPickerView ? (
                    <Box className={classes.rootBox} display="flex" justifyContent="space-between">
                        <MenuItem button onClick={isPickerAvailable ? handleEdit : noop}>
                            <Box
                                display="flex"
                                flex={1}
                                flexDirection={isDesktop ? 'column' : 'row'}
                                justifyContent="space-between"
                                alignItems={isDesktop ? 'initial' : 'center'}
                                paddingY={isDesktop ? 2 : 0}
                            >
                                <Box display="flex" flex={1} justifyContent="center" flexDirection="column">
                                    <Box display="flex" alignItems="center">
                                        <Typography className={clsx(classes.textBold, classes.textColor)}>
                                            {title}
                                        </Typography>
                                        {pickerTitle === 'location' && currentLocation?.address.town && (
                                            <Typography
                                                className={clsx(classes.textColor, classes.leaveWhiteSpace)}
                                            >
                                                {` | ${currentLocation?.address.town}`}
                                            </Typography>
                                        )}
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <Typography
                                            variant={isDesktop ? 'body1' : 'caption'}
                                            className={classes.textColor}
                                        >
                                            {caption}
                                        </Typography>

                                        {isPickerAvailable && !isDesktop && (
                                            <Typography
                                                className={clsx(classes.editText, classes.textBold)}
                                                variant="caption"
                                            >
                                                {t('GENERAL_EDIT')}
                                            </Typography>
                                        )}
                                        {isPickerAvailable && isDesktop && (
                                            <Button
                                                className={classes.tableButton}
                                                color="primary"
                                                variant="contained"
                                                data-cy="delivery-location-edit"
                                            >
                                                {t('GENERAL_EDIT')}
                                            </Button>
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        </MenuItem>

                        {searchButton && <MenuSearchButton handleOpenMenuSearch={handleOpenMenuSearch} />}
                        {!!openMenuSearch && (
                            <SuggestionsProvider>
                                <MenuSearch open={openMenuSearch} onClose={handleCloseMenuSearch} />
                            </SuggestionsProvider>
                        )}
                    </Box>
                ) : (
                    renderPickerView(handleEdit)
                ))}
            {isPickerAvailable && (
                <MerchantBrandedDialog
                    title={dialogTitle}
                    subtitle={subtitle}
                    icon={icon}
                    open={dialogOpen}
                    onClose={handleDialogClose}
                    disableBackdropClick={canBeClosed}
                >
                    <Formik
                        onSubmit={handleTableUpdated}
                        initialValues={initialValues}
                        validationSchema={deliveryLocationValidation}
                    >
                        {({ submitForm, isSubmitting, setFieldValue, setFieldTouched, isValid }) => (
                            <Form>
                                {checkSelectionEnabled &&
                                    scenarioToUse === OrderScenario.TABLE &&
                                    retrievalBy === 'BOTH' &&
                                    canChangeTableNumber && (
                                        <Box marginTop={1}>
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">
                                                    {t('OTT_LOCATION_PICKER_FETCH_BOTH')}
                                                </FormLabel>
                                                <RadioGroup value={fetchBy} onChange={handleFetchByChange}>
                                                    <FormControlLabel
                                                        value="CHECK_NUMBER"
                                                        control={<Radio color="primary" />}
                                                        label={t('BILL_CHECK_NUMBER')}
                                                    />
                                                    <FormControlLabel
                                                        value="TABLE_NUMBER"
                                                        control={<Radio color="primary" />}
                                                        label={t('BILL_TABLE_NUMBER')}
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Box>
                                    )}
                                {(scenarioToUse !== OrderScenario.TABLE ||
                                    (scenarioToUse === OrderScenario.TABLE &&
                                        (retrievalBy === 'TABLE_NUMBER' ||
                                            !retrievalBy ||
                                            !checkSelectionEnabled ||
                                            (retrievalBy === 'BOTH' && fetchBy === 'TABLE_NUMBER')))) && (
                                    <TextFormField
                                        name="tableNumber"
                                        label={
                                            scenarioToUse === OrderScenario.ORDER_TO_TABLE
                                                ? t('OTT_NUMBER')
                                                : t('PAT_START_MANUAL_TABLE_NUMBER_HINT')
                                        }
                                        variant="standard"
                                        type="number"
                                        onKeyDown={handleTableNumberKeyDown(setFieldTouched, 'tableNumber')}
                                        InputProps={{
                                            autoComplete: 'off',
                                            autoFocus: isDialogOpenInitially,
                                            inputMode: 'numeric',
                                            onBlur: handleTableNumberChange(setFieldValue, 'tableNumber')
                                        }}
                                    />
                                )}
                                {scenarioToUse === OrderScenario.TABLE &&
                                    checkSelectionEnabled &&
                                    (retrievalBy === 'CHECK_NUMBER' ||
                                        (retrievalBy === 'BOTH' && fetchBy === 'CHECK_NUMBER')) && (
                                        <TextFormField
                                            name="checkId"
                                            label={t('PAT_START_MANUAL_CHECK_NUMBER_HINT')}
                                            variant="standard"
                                            type="number"
                                            onKeyDown={handleTableNumberKeyDown(setFieldTouched, 'checkId')}
                                            InputProps={{
                                                autoComplete: 'off',
                                                autoFocus: isDialogOpenInitially,
                                                inputMode: 'numeric',
                                                onBlur: handleTableNumberChange(setFieldValue, 'checkId')
                                            }}
                                        />
                                    )}
                                <LoadingButton
                                    loading={isSubmitting}
                                    disabled={isSubmitting || !isValid}
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={submitForm}
                                    className={classes.confirmButton}
                                    data-cy="delivery-location-submit"
                                >
                                    {t('BUTTON_SUBMIT')}
                                </LoadingButton>
                            </Form>
                        )}
                    </Formik>
                </MerchantBrandedDialog>
            )}
        </>
    );
};
