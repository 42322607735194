import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAsyncData } from 'src/hooks/useAsyncData';
import { IAdjustmentReadResourceV10 } from 'components/basket/model/Basket';
import { IOperation, OperationStatus } from 'components/order/model/Operation';
import { IOrderJoinDetails, IOrderReadResourceV18, OrderErrorCodes } from 'components/order/model/Order';
import { EOrderNonceKey, orderApi } from 'components/order/orderApi';
import { OPERATION_POLLING_DELAY } from 'config/constants';
import { isDefined } from 'lib/typeInference';
import { useAuth } from 'lib/useAuth';
import { LocationRouteParams, ROUTES } from 'pages/routes';
import { ApplicationState } from 'store/store';
import { usePayQueryParams } from './usePayDateCheckId';
import { EGAEventName, useGAHelpers } from 'lib/useGAHelpers';
import { useLocalHistory } from 'lib/useLocalHistory';
import { useQuery } from 'src/hooks/useQuery';
import { ERequestStatus } from 'src/enums';

export interface RecalculateOrderParams {
    newSelectedReward?: IAdjustmentReadResourceV10 | null;
}

export function usePayAtTable() {
    const { merchantId, locationId } = useParams<LocationRouteParams>();
    const { checkId, tableNumber, date } = usePayQueryParams();
    const orderId = useQuery('orderId');
    const [isLoading, setIsLoading] = React.useState(true);
    const [isOrderNotFound, setIsOrderNotFound] = React.useState(false);

    const { user } = useAuth();
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const { logUserEvent } = useGAHelpers();
    const { push, replace } = useLocalHistory();

    const [orderDetails, setOrderDetails, setOrderDetailsInitial] = useAsyncData<IOrderReadResourceV18>();
    const [joinOrderDetails, setJoinOrderDetails] = useAsyncData<IOperation>();
    const [operationError, setOperationError, setOperationErrorInitial] = useAsyncData<IOperation>();

    const joinOrder = React.useCallback(() => {
        if ((isDefined(tableNumber) || isDefined(checkId)) && locationId && user) {
            const joiningDetails: IOrderJoinDetails = isDefined(checkId)
                ? {
                      locationId,
                      checkId
                  }
                : {
                      locationId,
                      deliveryLocation: tableNumber,
                      createIfNotFound: settings?.orderToTable?.multiPartEnabled
                  };
            if (isDefined(date)) {
                joiningDetails.checkDate = date;
            }
            if (joiningDetails.checkId || joiningDetails.deliveryLocation) {
                setOperationErrorInitial();
                setOrderDetailsInitial();
                setIsLoading(true);
                setIsOrderNotFound(false);
                setJoinOrderDetails(() => orderApi.joinOrder(joiningDetails)).then(response => {
                    orderApi.nonceHandlingByOperationStatus(response.status, EOrderNonceKey.JOIN_ORDER);
                    async function getOperationStatus() {
                        if (response.id) {
                            const operation: IOperation = await orderApi.getOperation(
                                response.id?.toString()
                            );
                            switch (operation.status) {
                                case OperationStatus.PENDING:
                                    // Recursive Poll for order completion
                                    setIsLoading(true);
                                    setTimeout(() => {
                                        getOperationStatus();
                                    }, OPERATION_POLLING_DELAY);

                                    orderApi.resetNonceByKey(EOrderNonceKey.JOIN_ORDER);
                                    break;
                                case OperationStatus.ERROR:
                                    if (
                                        operation.errorCode === OrderErrorCodes.CHECK_NOT_FOUND ||
                                        operation.errorCode === OrderErrorCodes.ORDER_NOT_FOUND ||
                                        operation.httpCode === 404
                                    ) {
                                        setIsOrderNotFound(true);
                                        setIsLoading(false);
                                    } else {
                                        setOperationError(() => Promise.resolve(operation));
                                        setIsLoading(false);
                                    }
                                    break;
                                case OperationStatus.TIMEOUT:
                                    throw new Error(operation.errorMessage || 'Operation error or timeout');
                                case OperationStatus.DONE: {
                                    if (!!operation && locationId && merchantId) {
                                        replace(
                                            '',
                                            { merchantId, locationId },
                                            'orderId=' + operation.resultId
                                        );
                                    }
                                }
                            }
                        }
                    }
                    if (response.id) {
                        getOperationStatus();
                    }
                });
            } else {
                setIsLoading(false);
            }
        }
    }, [
        checkId,
        date,
        locationId,
        merchantId,
        replace,
        setJoinOrderDetails,
        setOperationError,
        setOperationErrorInitial,
        setOrderDetailsInitial,
        settings?.orderToTable?.multiPartEnabled,
        tableNumber,
        user
    ]);

    React.useEffect(() => {
        joinOrder();
    }, [joinOrder]);

    React.useEffect(() => {
        const getCheckoutDetails = async () => {
            try {
                await setOrderDetails(() => orderApi.getCheckoutDetails(orderId, [], undefined, [], []));
                setIsLoading(false);
            } catch (err) {
                setIsLoading(false);
                replace('');
            }
        };
        if (orderId && user) {
            getCheckoutDetails();
        }
    }, [orderId, replace, setOrderDetails, user]);

    const multiPartOrderingEnabled = React.useMemo(
        () => settings?.orderToTable?.multiPartEnabled ?? false,
        [settings?.orderToTable?.multiPartEnabled]
    );

    const addMoreItemsSearchString = React.useMemo(() => {
        if (!multiPartOrderingEnabled) {
            return undefined;
        }
        let baseSearch = `orderId=${orderDetails?.data?.id}`;
        if (tableNumber ?? orderDetails?.data?.deliveryLocation) {
            baseSearch += `&tableNumber=${tableNumber ?? orderDetails?.data?.deliveryLocation}`;
        }
        return baseSearch;
    }, [multiPartOrderingEnabled, orderDetails?.data?.deliveryLocation, orderDetails?.data?.id, tableNumber]);

    const onAddMoreItems = React.useCallback(() => {
        logUserEvent(EGAEventName.AddMoreItems);
        push(ROUTES.MENU, {}, addMoreItemsSearchString);
    }, [addMoreItemsSearchString, logUserEvent, push]);

    const isEmpty = React.useMemo(
        () =>
            orderDetails?.status === ERequestStatus.SUCCESS &&
            !orderDetails?.data?.items?.length &&
            !orderDetails?.data?.adjustments?.length &&
            !orderDetails?.data?.payments?.length,
        [
            orderDetails?.data?.adjustments?.length,
            orderDetails?.data?.items?.length,
            orderDetails?.data?.payments?.length,
            orderDetails?.status
        ]
    );

    return {
        isEmpty,
        isLoading,
        orderDetails,
        operationError,
        isOrderNotFound,
        joinOrderDetails,
        multiPartOrderingEnabled,
        onAddMoreItems,
        setOperationErrorInitial
    };
}
