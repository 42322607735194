import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { useQuery } from 'src/hooks/useQuery';
import { useRouteScenario } from 'src/hooks/useRouteScenario';
import { IItemReadResourceV10 } from 'components/basket/model/Basket';
import { useLocationHelpers } from 'components/location/hooks/useLocationHelpers';
import { OperationStatus } from 'components/order/model/Operation';
import { createOrderCreateItemFromBasketItem, OrderErrorCodes } from 'components/order/model/Order';
import { EOrderNonceKey, orderApi } from 'components/order/orderApi';
import { OPERATION_POLLING_DELAY } from 'config/constants';
import { ConfirmDialog } from 'lib/ConfirmDialog';
import { QuantitySelector } from 'lib/QuantitySelector';
import { isDefined } from 'lib/typeInference';
import { useAuth } from 'lib/useAuth';
import { useLocalHistory } from 'lib/useLocalHistory';
import { LocationRouteParams, ROUTES } from 'pages/routes';
import { setReturnUrl } from 'store/auth/authActions';
import { resetBasket, updateBasketItemQuantity } from 'store/basket/basketActions';
import { getMenu } from 'store/menu/menuActions';
import { setLastRequest } from 'store/request/requestActions';
import { ApplicationState } from 'store/store';
import { EGAEventName, useGAHelpers } from 'lib/useGAHelpers';
import { getOrderNotes } from 'components/location/model/Location';
import { PayHeader } from 'components/pay/header/PayHeader';
import { PayToolbar } from 'components/pay/header/PayToolbar';
import { PayItems } from 'components/pay/order-item/PayItems';
import { ButtonV2 } from 'components/pay/ui/PayButton';
import { PayOrderNotes } from 'components/pay/notes/PayOrderNotes';
import { ELoadingSVG, RandomLoading } from 'lib/animations/RandomLoadingSVG';
import { PayBaseModal } from 'components/pay/modals/PayBaseModal';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        viewBasketButton: {
            padding: theme.spacing(1),
            marginBottom: theme.spacing(1)
        },
        loadingContainer: {
            display: 'flex',
            height: '100%',
            paddingBottom: theme.spacing(7),
            overflowY: 'hidden',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }
    })
);

const SPECIAL_ERROR_CODES: string[] = [
    OrderErrorCodes.DUPLICATE_ORDER_NONCE,
    OrderErrorCodes.DUPLICATE_PENDING_ORDER,
    OrderErrorCodes.UPDATING_THE_BILL
];

function getItemIdGenerator() {
    let index = 0;
    function generateId() {
        return index++;
    }
    return generateId;
}

export const PayBasket: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        items,
        notes: notesFromState,
        timeSlot,
        deliveryLocation
    } = useSelector((state: ApplicationState) => state.basket);
    const { menu, isLoading: isMenuLoading } = useSelector((state: ApplicationState) => state.menu);
    const { user } = useAuth();
    const { search } = useLocation();
    const { push, getParsedPath } = useLocalHistory();
    const { locationId } = useParams<LocationRouteParams>();
    const [scenario] = useRouteScenario();
    const { isWorkingHour } = useLocationHelpers();
    const orderId = useQuery('orderId');
    const tableNumber = useQuery('tableNumber');
    const dispatch = useDispatch();
    const { lastRequest } = useSelector((state: ApplicationState) => state.request);
    const { currentLocation, isCurrentLocationLoading } = useSelector(
        (state: ApplicationState) => state.locations
    );

    const [quantitySelectProductIndex, setQuantitySelectProductIndex] = React.useState(-1);
    const [quantity, setQuantity] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = React.useState(false);
    const [error, setError] = React.useState<string>();

    const { logUserEvent } = useGAHelpers();

    React.useEffect(() => {
        getMenu(
            locationId || '',
            timeSlot?.start,
            scenario,
            t('DIALOG_TIMESLOT_CHANGED_MESSAGE'),
            deliveryLocation
        )(dispatch);
    }, [dispatch, locationId, scenario, timeSlot?.start, t, deliveryLocation]);

    const notes = React.useMemo(() => getOrderNotes(currentLocation, scenario), [currentLocation, scenario]);

    const mappedItems: IItemReadResourceV10[] = React.useMemo(() => {
        const generateId = getItemIdGenerator();
        return items.reduce<IItemReadResourceV10[]>((acc, item) => {
            if (menu) {
                const productDetailsFromMenu = menu.productIdToProduct.get(item.productId);
                const category = menu.categories.find(({ id }) => item.categoryId === id);

                const modifiers = item.modifiers?.map(modifier => {
                    const detailedModifier =
                        category?.modifiers?.find(catModifier => catModifier.id === modifier.id) ??
                        menu.modifiers?.find(menuModifier => menuModifier.id === modifier.id);

                    return {
                        ...detailedModifier,
                        options: detailedModifier?.options?.filter(option =>
                            modifier.options.some(opt => opt.id === option.id)
                        )
                    };
                });

                if (productDetailsFromMenu && category && user) {
                    acc.push({
                        ...item,
                        id: generateId(),
                        userId: user.id,
                        productName: productDetailsFromMenu.title,
                        categoryName: category.title,
                        cost: item.price,
                        modifiers
                    } as IItemReadResourceV10);
                }
            }
            return acc;
        }, []);
    }, [items, menu, user]);

    const handleCloseQuantitySelect = React.useCallback(() => {
        setQuantitySelectProductIndex(-1);
    }, []);
    const handleOpenQuantitySelect = React.useCallback(
        (index: number) => {
            setQuantitySelectProductIndex(index);
            setQuantity(mappedItems[index].quantity);
        },
        [mappedItems]
    );
    const handleUpdateItemQuantity = React.useCallback(() => {
        updateBasketItemQuantity(quantitySelectProductIndex, quantity)(dispatch);
        handleCloseQuantitySelect();
    }, [quantitySelectProductIndex, quantity, dispatch, handleCloseQuantitySelect]);

    const handleItemClick = React.useCallback(
        (id: number) => {
            const index = mappedItems.findIndex(item => item.id === id);
            if (index >= 0) {
                handleOpenQuantitySelect(index);
            }
        },
        [handleOpenQuantitySelect, mappedItems]
    );
    const handleClickBack = React.useCallback(() => push(ROUTES.MENU, {}, search), [push, search]);
    const handleToggleConfirmationDialog = React.useCallback(
        () => setIsConfirmationOpen(!isConfirmationOpen),
        [isConfirmationOpen]
    );
    const handleOperation = React.useCallback(
        (operationId: string) => {
            async function getOperationStatus() {
                try {
                    setIsLoading(true);
                    setError(undefined);

                    const operation = await orderApi.getOperation(operationId);
                    orderApi.nonceHandlingByOperationStatus(
                        operation.status,
                        EOrderNonceKey.ADD_ITEM_TO_ORDER
                    );
                    switch (operation.status) {
                        case OperationStatus.PENDING:
                            // Recursive Poll for order completion
                            setTimeout(() => {
                                getOperationStatus();
                            }, OPERATION_POLLING_DELAY);

                            break;
                        case OperationStatus.ERROR:
                        case OperationStatus.TIMEOUT:
                            setIsLoading(false);

                            if (!operation.errorCode || !SPECIAL_ERROR_CODES.includes(operation.errorCode)) {
                                setError('Operation error or timeout');
                                handleToggleConfirmationDialog();
                            }
                            if (operation.errorCode === OrderErrorCodes.UPDATING_THE_BILL) {
                                setError(operation.errorMessage);
                                handleToggleConfirmationDialog();
                            }

                            break;
                        case OperationStatus.DONE: {
                            push(ROUTES.QUICKPAY.CHECKOUT, {}, `orderId=${orderId}`, {
                                showAddItemSuccess: true
                            });
                            setIsLoading(false);
                            setReturnUrl(getParsedPath(ROUTES.QUICKPAY.CHECKOUT))(dispatch);
                            // Redirect
                            resetBasket(dispatch);
                            break;
                        }
                    }
                } catch (orderStatusError) {
                    if (isDefined(orderStatusError?.response && orderStatusError.response.status === 404)) {
                        setError(orderStatusError.response.errorMessage);
                    } else {
                        setError(t('GENERAL_GENERIC_ERROR'));

                        if (!isDefined(orderStatusError?.response)) {
                            setError(t('DIALOG_ORDER_STATUS_UNKNOWN'));
                        }
                    }
                    setIsLoading(false);
                    handleToggleConfirmationDialog();
                }
            }
            getOperationStatus();
        },
        [dispatch, getParsedPath, handleToggleConfirmationDialog, orderId, push, t]
    );

    const handleAddOrder = React.useCallback(async () => {
        setLastRequest(handleAddOrder)(dispatch);
        setIsLoading(true);
        try {
            logUserEvent(EGAEventName.AddToOrder);
            const orderNotes: string[] = [];
            if (Array.isArray(notes) && !!mappedItems[0]) {
                notes.forEach(note => {
                    const currentNoteValue = notesFromState[note.title];
                    if (!!currentNoteValue) {
                        orderNotes.push(`${note.title}: ${currentNoteValue}`);
                    }
                });
            }

            const { id, status } = await orderApi.addOrderItems(Number(orderId), {
                items: mappedItems.map(createOrderCreateItemFromBasketItem),
                note: orderNotes ? orderNotes.join('; ') : undefined
            });
            if (id && status === OperationStatus.PENDING) {
                handleOperation(id.toString());
            }
        } catch (addToOrderError) {
            if (isDefined(addToOrderError?.response)) {
                setError(addToOrderError?.response?.errorMessage);
            } else {
                setError(t('DIALOG_NO_NETWORK_TEXT'));
            }

            setIsLoading(false);
            handleToggleConfirmationDialog();
        }
    }, [
        dispatch,
        handleOperation,
        handleToggleConfirmationDialog,
        logUserEvent,
        mappedItems,
        notes,
        notesFromState,
        orderId,
        t
    ]);

    const handleRetry = React.useCallback(() => {
        if (lastRequest) {
            lastRequest();
            setError('');
            handleToggleConfirmationDialog();
        }
    }, [handleToggleConfirmationDialog, lastRequest]);
    const handleConfirm = React.useCallback(() => {
        if (error) {
            handleRetry();
        }
    }, [error, handleRetry]);
    const handleErrorDialogClose = React.useCallback(() => {
        resetBasket(dispatch);
        orderApi.resetNonceByKey([EOrderNonceKey.ADD_ITEM_TO_ORDER]);
        push(ROUTES.JOURNEY.LANDING);
    }, [dispatch, push]);

    if (isLoading) {
        return (
            <Box className={classes.loadingContainer}>
                <RandomLoading
                    fallbackText={t('GENERAL_GENERIC_ERROR')}
                    enabledImages={[
                        ELoadingSVG.COFFEE_CUP,
                        ELoadingSVG.COFFEE_MUG,
                        ELoadingSVG.CUPCAKE,
                        ELoadingSVG.DONUT,
                        ELoadingSVG.FRYING_PAN,
                        ELoadingSVG.POPSICLE
                    ]}
                />
            </Box>
        );
    }

    return (
        <>
            <PayHeader variant="small">
                <PayToolbar
                    loading={isMenuLoading}
                    table={tableNumber}
                    onBack={handleClickBack}
                    disableEdit
                />
            </PayHeader>
            <Box pt={1}>
                <PayItems
                    loading={isMenuLoading}
                    items={mappedItems}
                    onItemClick={handleItemClick}
                    keepOpen
                />
            </Box>
            <PayOrderNotes loading={isCurrentLocationLoading} />
            <Box position="fixed" bottom="0" left="0" width="100%" p={1} pb={0}>
                <ButtonV2
                    disabled={!isWorkingHour}
                    color="primary"
                    variant="contained"
                    className={classes.viewBasketButton}
                    onClick={handleAddOrder}
                    fullWidth
                    data-cy="pat-basket-submit"
                >
                    {t('MPO_BASKET_SUBMIT_BTN')}
                </ButtonV2>
            </Box>
            <PayBaseModal open={quantitySelectProductIndex !== -1} onClose={handleCloseQuantitySelect}>
                <Box
                    width="100%"
                    height="100%"
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    pt={2}
                >
                    <QuantitySelector min={0} max={999} quantity={quantity} onChange={setQuantity} />

                    <ButtonV2
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleUpdateItemQuantity}
                    >
                        {quantity === 0 ? t('BASKET_REMOVE_ITEM') : t('PREORDER_UPDATE_BASKET_QUANTITY')}
                    </ButtonV2>
                </Box>
            </PayBaseModal>
            <ConfirmDialog
                open={isConfirmationOpen}
                disableBackdropClick
                disableEscapeKeyDown
                onClose={handleErrorDialogClose}
                confirmMessage={t('RETRY')}
                onConfirm={handleConfirm}
                description={t('DIALOG_ORDER_STATUS_UNKNOWN')}
            />
        </>
    );
};
