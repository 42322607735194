import React from 'react';
import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { PayLinkButton } from '../ui/PayLinkButton';
import { addOpacity } from 'lib/helpers';
import { useTranslation } from 'react-i18next';

interface PayorderNoteItemProps {
    title: string;
    onClick: (index: number) => void;
    value?: string;
    index: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderBottom: `1px dotted ${theme.palette.divider}`,
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            justifyContent: 'space-between',
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            '&:last-child': {
                borderBottom: 'none'
            }
        },
        value: {
            color: addOpacity(theme.palette.text.primary, 0.4)
        }
    })
);

export const PayorderNoteItem: React.FC<PayorderNoteItemProps> = ({ title, onClick, value, index }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const handleClick = React.useCallback(() => {
        onClick(index);
    }, [index, onClick]);
    return (
        <Box onClick={handleClick} className={classes.root}>
            <Box>
                <Typography>{title}</Typography>
                <Typography variant="body2" className={classes.value}>
                    {value}
                </Typography>
            </Box>
            <PayLinkButton variant="caption">
                {value ? t('PAT_QUICKPAY_BASKET_EDIT_NOTE') : t('PAT_QUICKPAY_BASKET_ADD_NOTE')}
            </PayLinkButton>
        </Box>
    );
};
