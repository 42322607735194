import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, createStyles, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import { PAY_MAX_WIDTH } from 'pages/pay';
import { ErrorCircle } from '../ui/ErrorCircle';
import { ButtonV2 } from '../ui/PayButton';
import { useAuth } from 'lib/useAuth';

interface IProps {
    errorMessage?: string | null;
    onSignIn: () => void;
    onAddPaymentMethod: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontSize: '24px',
            fontWeight: 500,
            color: theme.palette.text.primary
        },
        icon: {
            height: 100,
            width: 100
        },
        buttonContainer: {
            position: 'fixed',
            bottom: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            width: '100%',
            maxWidth: PAY_MAX_WIDTH,
            right: 0,
            padding: theme.spacing(1)
        }
    })
);

export const PayCardCheckError: React.FC<IProps> = ({ errorMessage, onSignIn, onAddPaymentMethod }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { isGuest } = useAuth();
    const theme = useTheme();

    return (
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" paddingTop={2}>
            <Typography className={classes.title}>{t('PAT_QUICKPAY_ERROR_TITLE')}</Typography>
            {isGuest && (
                <Box paddingX={1}>
                    <Typography align="center">{errorMessage}</Typography>
                </Box>
            )}
            {!isGuest && (
                <Box paddingX={1}>
                    <Typography align="center">{t('PAT_PAYMENT_CARD_REQUIRED')}</Typography>
                </Box>
            )}
            <Box paddingTop={5}>
                <ErrorCircle className={classes.icon} color={theme.palette.primary.main} />
            </Box>
            {isGuest && (
                <div className={classes.buttonContainer}>
                    <ButtonV2 fullWidth onClick={onSignIn}>
                        {t('ONBOARDING_SIGN_IN')}
                    </ButtonV2>
                </div>
            )}
            {!isGuest && (
                <div className={classes.buttonContainer}>
                    <ButtonV2 fullWidth onClick={onAddPaymentMethod}>
                        {t('BUTTON_ADD_CARD')}
                    </ButtonV2>
                </div>
            )}
        </Box>
    );
};
