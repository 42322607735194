import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { createStyles } from '@material-ui/styles';
import { useResponsive } from 'src/hooks/useResponsive';
import { usePaymentProvider } from 'src/integrations/PaymentProvider/hooks/usePaymentProvider';
import { MerchantTitle } from 'app/MerchantTitle';
import { CardView } from 'components/user/cards/CardView';
import { userApi } from 'components/user/userApi';
import { CONTENT_MAX_WIDTH } from 'config/constants';
import { MuiAppBar } from 'lib/appbar/MuiAppBar';
import { BottomDialog } from 'lib/BottomDialog';
import { ConfirmDialog } from 'lib/ConfirmDialog';
import logger from 'lib/logger';
import { Throbber } from 'lib/Throbber';
import { getUserCard } from 'store/auth/authActions';
import { ApplicationState } from 'store/store';
import { LoadingButton } from 'ui/LoadingButton';
import { useSnackbar } from 'notistack';
import { getOnCloseRedirectUrl } from 'components/user/localAuth';
import { useLocalHistory } from 'lib/useLocalHistory';
import { useParams } from 'react-router-dom';
import { BaseRouteParams } from './routes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonContainer: {
            maxWidth: CONTENT_MAX_WIDTH,
            padding: theme.spacing(1)
        },
        addCardDialog: (props: { isDesktop: boolean }) => ({
            ...(props.isDesktop
                ? {
                      maxWidth: CONTENT_MAX_WIDTH,
                      width: props.isDesktop ? CONTENT_MAX_WIDTH : 'auto'
                  }
                : {})
        })
    })
);

export const PaymentMethodsPage = () => {
    const { isDesktop } = useResponsive();
    const classes = useStyles({ isDesktop });
    const { t } = useTranslation();
    const { user, card } = useSelector((state: ApplicationState) => state.auth);
    const dispatch = useDispatch();
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const { enqueueSnackbar } = useSnackbar();
    const { push } = useLocalHistory();
    const { merchantId } = useParams<BaseRouteParams>();

    const [isAddCardOpen, setIsAddCardOpen] = React.useState(false);
    const [isRemoveCardOpen, setIsRemoveCardOpen] = React.useState(false);

    const [isCardLoading, setIsCardLoading] = React.useState(false);

    const handleAddCardOpen = React.useCallback(() => {
        setIsAddCardOpen(true);
    }, []);
    const handleAddCardClose = React.useCallback(() => {
        setIsAddCardOpen(false);
    }, []);
    const handleRemoveCardOpen = React.useCallback(() => {
        setIsRemoveCardOpen(true);
    }, []);
    const handleRemoveCardClose = React.useCallback(() => {
        setIsRemoveCardOpen(false);
    }, []);

    const removeCard = React.useCallback(async () => {
        if (card) {
            setIsCardLoading(true);
            try {
                await userApi.removeCard(card._id);
                if (user && user.id) {
                    await getUserCard(user.id)(dispatch);
                }
                handleRemoveCardClose();
            } catch (err) {
                logger.error('Error during card removal');
                enqueueSnackbar(t('AUTH_CARD_REMOVAL_ERROR'), { variant: 'error' });
            }
            enqueueSnackbar(t('DIALOG_REMOVE_PAYMENT_METHOD_SUCCESS_MESSAGE'), { variant: 'success' });
            setIsCardLoading(false);
        }
        return true;
    }, [card, enqueueSnackbar, t, user, handleRemoveCardClose, dispatch]);

    const handleCardAdded = React.useCallback(() => {
        if (user) {
            getUserCard(user._id)(dispatch).then(() => {
                handleAddCardClose();
                const returnUrl = getOnCloseRedirectUrl(merchantId ?? '');
                if (returnUrl) {
                    const [path, search] = returnUrl.split('?');
                    push(path, {}, search);
                }
            });
        }
    }, [dispatch, handleAddCardClose, merchantId, push, user]);

    const hasPaymentMethod = !!card;
    const cardDetails = {
        number: `************${card?.last4 || '****'}`,
        expiry: card?.expires || '',
        name: user?.fullName || '',
        issuer: card?.type ? card?.type.toLowerCase() : ''
    };

    const { isProviderLoading, addCardComponent } = usePaymentProvider(
        { type: 'digitalWallet' },
        { onCardAdded: handleCardAdded }
    );
    return (
        <Box height="100%" display="flex" flexDirection="column">
            <MerchantTitle title={t('ACCOUNT_PAYMENT')} />
            <MuiAppBar title={t('ACCOUNT_PAYMENT')} />
            <Box flex={1} paddingTop={2} display="flex" flexDirection="column">
                <Box>
                    {hasPaymentMethod && <CardView {...cardDetails} />}
                    {!hasPaymentMethod && isCardLoading && <Throbber text={t('GENERAL_LOADING')} />}
                    {!hasPaymentMethod && !isCardLoading && (
                        <Box padding={1}>
                            <Typography>{t('PAYMENTS_ADD_MESSAGE')}</Typography>
                        </Box>
                    )}
                </Box>
                <Box marginTop="auto" className={classes.buttonContainer}>
                    {hasPaymentMethod && (
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleRemoveCardOpen}
                        >
                            {t('REMOVE_PAYMENT_METHOD')}
                        </LoadingButton>
                    )}
                    {!hasPaymentMethod && isCardLoading && (
                        <LoadingButton variant="contained" color="primary" disabled fullWidth>
                            {t('GENERAL_LOADING')}
                        </LoadingButton>
                    )}
                    {!hasPaymentMethod && !isCardLoading && (
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleAddCardOpen}
                        >
                            {t('BUTTON_ADD_CARD')}
                        </LoadingButton>
                    )}
                </Box>
            </Box>

            <BottomDialog
                paperClassName={classes.addCardDialog}
                fullScreen
                onClose={handleAddCardClose}
                open={isAddCardOpen}
                disableEnforceFocus
            >
                {!!settings && isAddCardOpen ? (
                    <React.Fragment>
                        <MuiAppBar title={t('CHECKOUT_ADD_CARD')} onBack={handleAddCardClose} icon={Close} />
                        {!isProviderLoading && addCardComponent}
                        {isProviderLoading && <Throbber text={t('GENERAL_LOADING')} />}
                    </React.Fragment>
                ) : null}
            </BottomDialog>

            <ConfirmDialog
                open={isRemoveCardOpen}
                isLoading={isCardLoading}
                title={t('REMOVE_PAYMENT_METHOD')}
                description={t('DIALOG_REMOVE_PAYMENT_METHOD_CONFIRMATION')}
                confirmMessage={t('DIALOG_REMOVE_CARD_OK')}
                cancelMessage={t('BUTTON_CANCEL')}
                onClose={handleRemoveCardClose}
                onConfirm={removeCard}
            />
        </Box>
    );
};
