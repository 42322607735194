import * as React from 'react';
import { useRouteScenario } from 'src/hooks/useRouteScenario';
import { OrderScenario } from 'components/order/model/Order';
import { ViewBasketPage } from './view-basket';
import { PayBasket } from './pay/pay-basket';

export const Basket: React.FC = () => {
    const [scenario] = useRouteScenario();

    return scenario === OrderScenario.TABLE ? <PayBasket /> : <ViewBasketPage />;
};
