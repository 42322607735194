import { PaymentSessionResponse } from 'src/integrations/PaymentProvider/ApplePayButton';
import { uuid } from 'uuidv4';
import { HttpClient } from 'components/http/HttpClient';
import logger from 'lib/logger';
import { IOrdersOptions } from 'store/orders/ordersActions';
import { IOperation, isOperation, OperationStatus } from './model/Operation';
import {
    IAdjustmentReadResourceV10,
    IDeviceDataResponse,
    IGiftcardResource,
    IOrderCreatePaymentV8,
    IOrderCreateResourceV8,
    IOrderJoinDetails,
    IOrderReadResourceV12,
    IOrderReadResourceV18,
    isOrderReadResource,
    IUpdateOrderDetails
} from './model/Order';

export enum EOrderNonceKey {
    JOIN_ORDER = 'JOIN_ORDER',
    ADD_ITEM_TO_ORDER = 'ADD_ITEM_TO_ORDER',
    CREATE_ORDER = 'CREATE_ORDER',
    UPDATE_ORDER = 'UPDATE_ORDER'
}

class OrderApi<T> extends HttpClient {
    private nonces: Record<string, string> = {
        [EOrderNonceKey.JOIN_ORDER]: uuid(),
        [EOrderNonceKey.CREATE_ORDER]: uuid(),
        [EOrderNonceKey.UPDATE_ORDER]: uuid(),
        [EOrderNonceKey.ADD_ITEM_TO_ORDER]: uuid()
    };

    private payloadHash: Record<string, string | null> = {
        [EOrderNonceKey.JOIN_ORDER]: null
    };

    constructor(version: '16' | '21' | '22') {
        super({
            baseURL: process.env.ORDER_SERVICE_URL,
            url: '/',
            version
        });
    }

    public async getOrder(orderId: string): Promise<T> {
        const response = await this.getRequest<T>({
            url: `orders/${orderId}`
        });

        if (!isOrderReadResource(response)) {
            // Don't want to break the web app if we get odd data
            logger.warn(
                `Typechecking failed, expected IOrderReadResource, but got ${String(response)}`,
                response
            );
        }
        return response;
    }

    public async getOrders(options: IOrdersOptions): Promise<{ items: T[] }> {
        const response = await this.getRequest<{ items: T[] }>({
            url: 'orders',
            configs: {
                params: {
                    ...options,
                    isOpen: options.isOpen ?? false,
                    limit: options.limit ?? 10,
                    sort: '-id'
                }
            }
        });

        return response;
    }

    public async getOperation(operationId: string): Promise<IOperation> {
        const response = await this.getRequest<IOperation>({ url: `operations/${operationId}` });
        if (!isOperation(response)) {
            // Don't want to break the web app if we get odd data
            logger.warn(`Typechecking failed, expected IOperation, but got ${String(response)}`, response);
        }
        return response;
    }

    public async createOrder(orderData: IOrderCreateResourceV8): Promise<IOperation> {
        const nonce = this.nonces[EOrderNonceKey.CREATE_ORDER];
        const response = await this.postRequest<IOrderCreateResourceV8, IOperation>({
            url: '/orders',
            data: orderData,
            headerConfigs: {
                nonce
            }
        });

        if (response) {
            this.nonceHandlingByOperationStatus(response.status, EOrderNonceKey.CREATE_ORDER);
        }

        if (!isOperation(response)) {
            // Don't want to break the web app if we get odd data
            logger.warn(`Typechecking failed, expected IOperation, but got ${String(response)}`, response);
        }
        return response;
    }

    public async joinOrder(data: IOrderJoinDetails): Promise<IOperation> {
        this.validatePayloadAgainstHash(data, EOrderNonceKey.JOIN_ORDER);

        const nonce = this.nonces[EOrderNonceKey.JOIN_ORDER];
        const response = await this.postRequest<
            { deliveryLocation?: string; checkId?: string; locationId: string },
            IOperation
        >({
            url: '/orders/users',
            data,
            headerConfigs: {
                nonce
            }
        });

        if (response) {
            this.nonceHandlingByOperationStatus(response.status, EOrderNonceKey.JOIN_ORDER);
        }

        return response;
    }

    public async leaveOrder(orderId: number) {
        return this.deleteRequest({
            url: `/orders/${orderId}/users`
        });
    }

    public async updateOrder(orderId: number, data: Partial<IUpdateOrderDetails>): Promise<IOperation> {
        const nonce = this.nonces[EOrderNonceKey.UPDATE_ORDER];
        const response = await this.putRequest<Partial<IUpdateOrderDetails>, IOperation>({
            url: `/orders/${orderId}`,
            data,
            headerConfigs: {
                nonce
            }
        });

        if (response) {
            this.nonceHandlingByOperationStatus(response.status, EOrderNonceKey.UPDATE_ORDER);
        }

        return response;
    }

    public async getCheckoutDetails(
        orderId: string,
        adjustments: Partial<IAdjustmentReadResourceV10>[] = [],
        splits?: number,
        claimedItemIds: number[] = [],
        payments?: Partial<IOrderCreatePaymentV8[]>
    ): Promise<T> {
        const data: {
            adjustments?: Partial<IAdjustmentReadResourceV10>[];
            splits?: number;
            itemIds?: number[];
            payments?: Partial<IOrderCreatePaymentV8[]>;
        } = {};
        if (splits) {
            data.splits = splits;
        }
        if (adjustments.length > 0) {
            data.adjustments = adjustments;
        }
        if (claimedItemIds.length > 0) {
            data.itemIds = claimedItemIds;
        }

        if (payments && payments.length > 0) {
            data.payments = payments;
        }

        return this.postRequest<{ adjustments?: Partial<IAdjustmentReadResourceV10>[]; splits?: number }, T>({
            url: `/orders/${orderId}/checkout`,
            data
        });
    }

    // FIXME: update the models
    public async addOrderItems(orderId: number, data: any): Promise<IOperation> {
        const nonce = this.nonces[EOrderNonceKey.ADD_ITEM_TO_ORDER];
        const response = await this.postRequest<any, IOperation>({
            url: `/orders/${orderId}/items`,
            data,
            headerConfigs: {
                nonce
            }
        });

        if (response) {
            this.nonceHandlingByOperationStatus(response.status, EOrderNonceKey.ADD_ITEM_TO_ORDER);
        }

        return response;
    }

    public async createPaymentSession(validationUrl: string, locationId: string, locationName: string) {
        return this.postRequest<any, PaymentSessionResponse>({
            url: '/applepay',
            data: {
                validationUrl,
                locationId,
                locationName
            }
        });
    }

    public async claimItems(itemIds: number[], orderId: string): Promise<T> {
        return this.putRequest<{ itemIds: number[] }, T>({
            url: `/orders/${orderId}/users/claims`,
            data: { itemIds }
        });
    }

    public resetNonceByKey = (key: EOrderNonceKey | EOrderNonceKey[]) => {
        if (Array.isArray(key)) {
            key.forEach(actionKey => {
                this.nonces[actionKey as string] = uuid();
            });
        } else {
            this.nonces[key as string] = uuid();
        }
    };

    public nonceHandlingByOperationStatus = (
        status: IOperation['status'],
        nonceKey: EOrderNonceKey | EOrderNonceKey[]
    ) => {
        switch (status) {
            case OperationStatus.DONE:
            case OperationStatus.ERROR:
                this.resetNonceByKey(nonceKey);
                break;
            default:
                break;
        }
    };

    public createDeviceData = (): Promise<IDeviceDataResponse> =>
        this.postRequest({
            url: '/Worldpay/devicedata',
            data: {}
        });

    public getGiftcard = (giftcardNumber: string, provider: string, locationId: string) =>
        this.getRequest<IGiftcardResource>({
            url: `giftcards/${giftcardNumber}`,
            configs: {
                params: {
                    provider,
                    locationId
                }
            }
        });

    // Compare current and previous payload hashes to reset nonce if needed
    private validatePayloadAgainstHash = (payload: Record<any, any>, nonceKey: EOrderNonceKey) => {
        import('object-hash')
            .then(hash => {
                const payloadHash = hash.default(payload, {
                    excludeKeys: key => key === 'nonce'
                });
                const currentHash = this.payloadHash[nonceKey];
                if (payloadHash !== currentHash) {
                    this.resetNonceByKey(nonceKey);
                    this.payloadHash[nonceKey] = payloadHash;
                }
            })
            .catch(error => {
                throw new Error(`Couldn't load object-hash ${error.message}`);
            });
    };
}

export const legacyOrderApi = new OrderApi<IOrderReadResourceV12>('16');
export const orderApi = new OrderApi<IOrderReadResourceV18>('21');
