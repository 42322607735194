import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { getFadeDownGradient } from 'lib/helpers';
import { useLocalHistory } from 'lib/useLocalHistory';
import { PAY_MAX_WIDTH } from 'pages/pay';
import { ROUTES } from 'pages/routes';
import { setBasket } from 'store/basket/basketActions';
import { ButtonV2 } from '../ui/PayButton';
import { PayCheckIcon } from './PayCheckIcon';

const useStyles = makeStyles((theme: Theme) => ({
    buttonsRoot: {
        position: 'fixed',
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100%',
        maxWidth: PAY_MAX_WIDTH,
        right: 0,
        padding: theme.spacing(1),
        paddingTop: theme.spacing(2),
        background: getFadeDownGradient(theme.palette.background.default, [0, 0.9, 1], ['0%', '50%', ' 100%'])
    },
    placeholder: {
        width: '100%'
    },
    title: {
        fontWeight: 700,
        fontSize: '18px'
    },
    textRow: {
        display: 'flex',
        alignItems: 'flex-start',
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(0.5)
    },
    icon: {
        height: '24px',
        width: '24px',
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(0.25)
    },
    text: {
        fontWeight: 400,
        fontSize: '16px'
    },
    button: {
        marginBottom: theme.spacing(1)
    },
    link: {
        textDecoration: 'none',
        fontWeight: 700,
        color: theme.palette.primary.main
    },
    signInText: {
        fontSize: '16px'
    }
}));

interface PayGuestConversionProps {
    locationId: string;
}

export const PayGuestConversion: React.FC<PayGuestConversionProps> = ({ locationId }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [height, setHeight] = React.useState(0);
    const ref = React.useRef<HTMLDivElement>(null);
    const { getParsedPath, push } = useLocalHistory();
    const handleClick = React.useCallback(() => {
        setBasket({ locationId })(dispatch);
        push(ROUTES.USER.REGISTER);
    }, [dispatch, locationId, push]);
    React.useEffect(() => {
        const rect = ref.current?.getBoundingClientRect();
        if (rect && rect.height !== height) {
            setHeight(rect.height);
        }
    }, [height]);
    return (
        <Box>
            <Box px={2} pt={3.5}>
                <Typography className={classes.title}>{t('PAT_QUICKPAY_GUEST_CONVERSION_TITLE')}</Typography>
                {t('PAT_QUICKPAY_GUEST_CONVERSION_CONTENT')
                    .split('\n')
                    .map((item, index) => (
                        <div className={classes.textRow} key={`guest-conversion-${index}`}>
                            <PayCheckIcon className={classes.icon} />
                            <Typography className={classes.text}>{item}</Typography>
                        </div>
                    ))}
            </Box>
            <div className={classes.buttonsRoot} ref={ref}>
                <ButtonV2 onClick={handleClick} className={classes.button}>
                    {t('ORDER_STATUS_GUEST_CONVERT')}
                </ButtonV2>
                <Typography className={classes.signInText} align="center">
                    {t('AUTH_ALREADY_HAVE_ACCOUNT') + ' '}
                    <Link className={classes.link} to={getParsedPath(ROUTES.USER.LOGIN)}>
                        {t('ONBOARDING_SIGN_IN')}
                    </Link>
                </Typography>
            </div>
            <div className={classes.placeholder} style={{ height }} />
        </Box>
    );
};
