import isEqual from 'lodash/isEqual';
import { updateLocalBasket } from 'components/basket/localStore';
import { IBasketAdjustment, IBasketItem } from 'components/basket/model/Basket';
import { EOrderNonceKey, legacyOrderApi } from 'components/order/orderApi';
import { Timeslot } from 'components/timeslots/model/Timeslot';
import { isDefined } from 'lib/typeInference';
import { BasketAction } from './basketActions';
import { BasketActionTypes } from './basketActionTypes';
export interface BasketState {
    locationId: string;
    items: (IBasketItem & { price: number })[];
    adjustments: IBasketAdjustment[];
    deliveryLocation?: string;
    prepTimePadding?: number;
    checkId?: string;
    timeSlot?: Timeslot;
    notes: Record<string, string | undefined>;
    suggestionShowed: boolean;
}

export const initialState: BasketState = {
    locationId: '',
    items: [],
    adjustments: [],
    checkId: undefined,
    deliveryLocation: undefined,
    notes: {},
    suggestionShowed: false
};

export default function (state: BasketState = initialState, action: BasketAction): BasketState {
    let newState = state;
    switch (action.type) {
        case BasketActionTypes.ADD_ITEM: {
            // If an item is already in the basket with same modifiers and productId, combine quantities
            const newItems = [...state.items];
            const matchingItems = newItems.filter(item => {
                const productIdMatch = item.productId === action.item.productId;
                let modifiersMatch = true;
                if (isDefined(item.modifiers)) {
                    if (isDefined(action.item.modifiers)) {
                        modifiersMatch = isEqual(item.modifiers.sort(), action.item.modifiers.sort());
                    } else {
                        modifiersMatch = false;
                    }
                } else {
                    modifiersMatch = !isDefined(action.item.modifiers);
                }

                return productIdMatch && modifiersMatch;
            });
            if (matchingItems.length !== 0) {
                const index = newItems.indexOf(matchingItems[0]);
                if (index !== -1) {
                    newItems[index].quantity += action.item.quantity;
                    newState = { ...state, items: newItems };
                    break;
                }
            }
            newState = { ...state, items: [...newItems, action.item] };
            break;
        }
        case BasketActionTypes.ADD_ITEMS: {
            // If an item is already in the basket with same modifiers and productId, combine quantities
            const newItems = [...state.items];
            const additionalItems = [];

            for (const itemToAdd of action.items) {
                const matchingItems = newItems.filter(item => {
                    const productIdMatch = item.productId === itemToAdd.productId;
                    const categoryIdMatch = item.categoryId === itemToAdd.categoryId;
                    let modifiersMatch = true;
                    if (isDefined(item.modifiers)) {
                        if (isDefined(itemToAdd.modifiers)) {
                            modifiersMatch = isEqual(item.modifiers.sort(), itemToAdd.modifiers.sort());
                        } else {
                            modifiersMatch = false;
                        }
                    } else {
                        modifiersMatch = !isDefined(itemToAdd.modifiers);
                    }
                    const hasReferenceId = isDefined(item.referenceId);
                    let parentReferenceIdMatch = false;
                    if (!hasReferenceId) {
                        parentReferenceIdMatch = item.parentReferenceId === itemToAdd.parentReferenceId;
                    }
                    return (
                        productIdMatch &&
                        categoryIdMatch &&
                        modifiersMatch &&
                        !hasReferenceId &&
                        parentReferenceIdMatch
                    );
                });
                if (matchingItems.length !== 0) {
                    const index = newItems.indexOf(matchingItems[0]);
                    if (index !== -1) {
                        newItems[index].quantity += itemToAdd.quantity;
                        continue;
                    }
                }
                // Item is not currently in basket with same modifier options
                // or it has modifier products
                additionalItems.push(itemToAdd);
            }

            newState = { ...state, items: [...newItems, ...additionalItems] };
            break;
        }
        case BasketActionTypes.REMOVE_REWARD: {
            const newAdjustments = state.adjustments.filter(adj => adj.awardId !== action.rewardId);
            newState = { ...state, adjustments: newAdjustments };
            break;
        }
        case BasketActionTypes.ADD_AWARD_ADJUSTMENT: {
            const newAdjustments = [...state.adjustments];
            newAdjustments.push({ awardId: action.award.awardId, quantity: action.award.quantity });
            newState = { ...state, adjustments: newAdjustments };
            break;
        }
        case BasketActionTypes.RESET_REWARDS:
            newState = { ...state, adjustments: [] };
            break;
        case BasketActionTypes.UPDATE_QUANTITY: {
            let newItems = [...state.items];
            const originQuantity = newItems[action.itemIndex].quantity;
            newItems[action.itemIndex].quantity = action.quantity;
            const { referenceId } = newItems[action.itemIndex];
            if (referenceId) {
                for (const modifierProduct of newItems.filter(
                    item => item.parentReferenceId === referenceId
                )) {
                    modifierProduct.quantity = action.quantity * (modifierProduct.quantity / originQuantity);
                }
            }
            newItems = newItems.filter(item => item.quantity !== 0);
            newState = { ...state, items: newItems };
            break;
        }
        case BasketActionTypes.REMOVE_ITEM: {
            let newItems = [...state.items];
            const itemToRemove = newItems[action.itemIndex];
            // Also remove modifier products
            newItems = newItems.filter((item, index) => {
                const refIdChild =
                    itemToRemove.referenceId && itemToRemove.referenceId === item.parentReferenceId;
                return refIdChild || index === action.itemIndex;
            });
            newState = { ...state, items: newItems };
            break;
        }
        case BasketActionTypes.SET_BASKET:
            newState = { ...state, ...action.basket };
            break;
        case BasketActionTypes.SET_BASKET_LOCATION_ID:
            newState = { ...state, locationId: action.locationId };
            break;
        case BasketActionTypes.RESET_BASKET:
            newState = {
                ...initialState,
                deliveryLocation: state.deliveryLocation,
                checkId: state.checkId
            };
            // Why we shouldn't do that?
            return newState; // Don't update local basket here
        case BasketActionTypes.SET_DELIVERY_LOCATION:
            legacyOrderApi.resetNonceByKey(EOrderNonceKey.JOIN_ORDER);
            newState = { ...state, deliveryLocation: action.deliveryLocation, checkId: undefined };
            break;
        case BasketActionTypes.SET_CHECK_ID:
            legacyOrderApi.resetNonceByKey(EOrderNonceKey.JOIN_ORDER);
            newState = { ...state, checkId: action.checkId, deliveryLocation: undefined };
            break;
        case BasketActionTypes.SET_NOTE:
            newState = { ...state, notes: { ...state.notes, [action.title]: action.note } };
            break;
        case BasketActionTypes.SET_PREP_TIME_PADDING:
            newState = { ...state, prepTimePadding: action.prepTimePadding };
            break;
        case BasketActionTypes.SET_TIMESLOT:
            // TODO: not sure if we can do that safe
            newState = { ...state, timeSlot: action.timeslot };
            if (!newState.timeSlot) {
                delete newState.timeSlot;
            }
            break;
        case BasketActionTypes.BASKET_SET_SUGGESTIONS_SHOWED:
            newState = { ...state, suggestionShowed: true };
            break;
        default:
            break;
    }
    if (Object.values(BasketActionTypes).includes(action.type)) {
        updateLocalBasket(newState);
    }
    return newState;
}
